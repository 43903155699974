import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-layout"},[_c(VNavigationDrawer,{staticClass:"elevation-1",attrs:{"app":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-2 text-center"},[_c(VBtn,{attrs:{"small":"","href":"https://firelayer.io/docs","target":"_blank","text":""}},[_vm._v("Docs")]),_c(VBtn,{attrs:{"small":"","href":"https://github.com/firelayer/starter-template","target":"_blank","text":""}},[_vm._v("Feedback")]),_c(VBtn,{attrs:{"small":"","href":"https://github.com/firelayer/starter-template","target":"_blank","text":""}},[_vm._v("Support")])],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_c('div',[_c('div',{staticClass:"title font-weight-bold"},[_vm._v("Firelayer Admin")]),_c('div',{staticClass:"overline"},[_vm._v("1.0.0 Valiant")])])])],1)],1),_c(VList,[_c(VSubheader,{staticClass:"mt-1 overline"},[_vm._v("Menu")]),_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.text,attrs:{"to":item.link,"exact":item.exact}},[_c(VListItemAction,[_c(VIcon,[_vm._v(_vm._s(item.icon))])],1),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"menu-text"},[_vm._v(_vm._s(item.text))])],1)],1)})],2)],1),_c(VAppBar,{attrs:{"app":""}},[_c(VAppBarNavIcon,{staticClass:"hidden-lg-and-up",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c(VSpacer),_c('div',[_c('UserMenu')],1)],1),_c(VMain,[_c(VContainer,{attrs:{"fill-height":"","fluid":""}},[_c(VLayout,[_vm._t("default")],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }